<template>
  <div class="contacts-wrapper flex">
    <div class="contacts-container px-2 py-4">
      <div v-for="letter in sortedLetters" :key="letter">
        <div class="contact-group">
          <div :id="`group-${letter}`" class="letter h4 mb-0">
            {{ letter }}
          </div>
          <ul class="contact-list">
            <NuxtLink
              v-for="(address, index) in contactGroups[letter]"
              :key="address.title"
              :class="{
                'mb-2 border-none': index + 1 == contactGroups[letter].length,
              }"
              class="contact-item flex-start flex border-b-[0.5px] px-2 pb-2 pt-4"
              style="
                font-family: Lato;
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: center;
              "
              @click="
                $parent?.$parent?.$parent?.$emit('close'),
                  handleBrandClick(address.id)
              "
              :to="{
                path: `${$features?.home ? `/products` : '/'}`,
                query: { brands: address.id },
              }"
            >
              {{ address.name }}
            </NuxtLink>
          </ul>
        </div>
      </div>
    </div>

    <div class="alphabet-list" id="alphabet-list">
      <div
        v-for="letter in sortedLetters"
        :key="letter"
        class="alphabet-item h5 text-black"
        @click="scrollToGroup(letter)"
      >
        {{ letter }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  FormattedAddress,
  Brand,
} from "~/types/InformativePages/Header/CategoryBundleBrand";
import { useFiltersStore } from "~/store/Features/ProductManagement/filtersStore";
const props = defineProps({
  brands: Array as PropType<Brand[]>,
  layout: { type: Number, default: 1 },
});

const contactGroups = ref<Record<string, FormattedAddress[]>>({});
const sortedLetters = ref<string[]>([]);

const formattedAddresses = ref<FormattedAddress[]>([]);
const route = useRoute();
const filterStore = useFiltersStore();

const reFormatAddresses = () => {
  formattedAddresses.value = [];
  props.brands?.forEach((brand) => {
    formattedAddresses.value.push({
      name: brand.title,
      letter: brand.title.charAt(0).toLocaleUpperCase(),
      id: brand.id,
    });
  });
  localStorage.setItem(
    "formattedAddresses",
    JSON.stringify(formattedAddresses.value),
  );
  sortContacts();
};

const sortContacts = () => {
  sortedLetters.value = [
    ...new Set(formattedAddresses.value.map((address) => address.letter)),
  ].sort();

  sortedLetters.value.forEach((letter) => {
    contactGroups.value[letter] = formattedAddresses.value.filter(
      (address) => address.letter === letter,
    );
  });
};

const scrollToGroup = (letter: string) => {
  const groupContainer = document.getElementById(`group-${letter}`);
  if (groupContainer) {
    window.scrollTo({
      top: 2000,
      behavior: "smooth",
    });
    groupContainer.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }
};

const handleBrandClick = (brandId: string) => {
  const currentPath = route.path;
  if (currentPath === "/products" || !useNuxtApp().$features?.home) {
    filterStore.clearAllFilters();
    filterStore.applyFilter("brands", brandId);
  }
};

onMounted(() => {
  const storedAddresses = JSON.parse(
    localStorage.getItem("formattedAddresses") as string,
  );
  if (storedAddresses) {
    formattedAddresses.value = storedAddresses;
    sortContacts();
  } else {
    reFormatAddresses();
  }
});

watch(
  () => props.brands,
  (newBrands) => {
    if (newBrands && newBrands.length > 0) {
      reFormatAddresses();
    }
  },
  { immediate: true },
);
</script>

<style scoped>
.contacts-wrapper {
  display: flex;
  align-items: flex-end;
  margin-left: -8px;
  margin-right: -8px;
}

.contacts-container {
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
}

.alphabet-list {
  position: sticky;
  top: 20px;
}
</style>
