<template>
  <nav v-if="version !== 'checkout'">
    <ul>
      <li v-if="isStaging">
        <Lang class="hidden md:block" />
      </li>

      <li>
        <NuxtLink v-if="userStore.user" :to="{ name: 'account' }">
          <IconsUserIcon />
          <div class="icon-slogan">{{ $t("common.titles.account") }}</div>
        </NuxtLink>

        <div v-else class="flex items-center">
          <NuxtLink :to="{ name: 'login' }">
            <IconsUserIcon />
            <div data-cy="sign-in" class="icon-slogan">
              {{ $t("common.titles.sign_in") }}
            </div>
          </NuxtLink>
          <span class="hidden px-1 lg:inline">/</span>
          <NuxtLink :to="{ name: 'register' }">
            <div data-cy="register" class="icon-slogan">
              {{ $t("common.titles.register") }}
            </div>
          </NuxtLink>
        </div>
      </li>
      <li v-if="$features?.wishlist">
        <NuxtLink :to="{ name: 'wishlist' }">
          <IconsFavIcon />
          <div data-cy="wishlist" class="icon-slogan">
            {{ $t("common.titles.wishlist") }}
          </div>
        </NuxtLink>
      </li>

      <li class="relative">
        <NuxtLink :to="{ name: 'cart' }" @mouseover="showCart = true">
          <IconsCartIcon :fill-color="$color" />
          <div class="icon-slogan">{{ $t("common.titles.cart") }}</div>
          <div class="cart-counter">
            {{ cartLength }}
          </div>
        </NuxtLink>
        <Quickview v-if="showCart" @close="showCart = false" />
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { useCartStore } from "~/store/Features/Checkout/cartStore";
import { useUserStore } from "~/store/common/userStore";
import Lang from "./Lang.vue";
import Quickview from "../Checkout/CartQuickView/Quickview.vue";

defineProps({
  version: {
    type: String,
    default: "",
  },
});

const showCart = ref(false);
const userStore = useUserStore();
const cartStore = useCartStore();
const nuxtApp = useNuxtApp();

const cartLength = computed(() => {
  return cartStore.cart?.total_quantity || 0;
});
const isStaging = computed(
  () => nuxtApp.$config.public.ENVIRONMENT == "staging",
);
</script>
