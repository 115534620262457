<template>
  <div class="flex flex-col gap-3 bg-grey-surface p-6">
    <div class="flex flex-col pb-3">
      <div class="flex items-center justify-between">
        <div class="h2 font-bold uppercase">{{ $t("cart.subtotal") }}</div>
        <div class="h2 font-bold">{{ cartStore.cart?.sub_total }}</div>
      </div>
      <div class="h5 font-sm opacity-[40%]">
        {{ $t("cart.delivery_and_vat") }}
      </div>
    </div>
    <NuxtLink
      class="btn-secondary rounded-auto block h-10 capitalize"
      :to="paths.cart"
    >
      {{ $t("cart.view_cart") }}
    </NuxtLink>
    <NuxtLink
      class="btn-primary rounded-auto block h-11 capitalize"
      :to="userStore?.user ? paths.checkout : paths.login"
    >
      {{ $t("cart.checkout") }}
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "~/store/common/userStore";
import paths from "~/constants/paths";
import { useCartStore } from "~/store/Features/Checkout/cartStore";

const userStore = useUserStore();
const cartStore = useCartStore();
</script>
