<template>
  <li
    class="home-no-bullets relative z-[2] h-full transition"
    :class="
      $features?.categories?.screens?.categories_screen === '1' &&
      type === 'category'
        ? 'relative'
        : ''
    "
  >
    <div
      data-cy="categories"
      class="display-1 flex h-full w-[200px] cursor-pointer flex-col justify-center text-sm font-bold capitalize"
      :class="{ 'border-b border-main': showDropdown }"
      @mouseenter="showDropdown = true"
      @mouseleave="showDropdown = false"
    >
      <div class="z-4 flex h-full items-center px-5">
        {{ "All Categories" }}
        <IconsArrowUpIcon
          class="transition-all"
          :class="showDropdown ? 'rotate-0' : '-rotate-180'"
        />
      </div>
      <div
        class="absolute left-[2] top-[2.8rem] z-[9] mt-[1px] w-full whitespace-nowrap pb-4"
        v-if="showDropdown"
      >
        <div class="flex w-[700px]">
          <div class="w-[280px] bg-white pb-4">
            <NuxtLink
              @click.prevent="handleCategoryClick(category.id)"
              :to="{
                path: $features?.home ? paths.products.index : paths.home,
                query: {
                  categories: category?.id,
                },
              }"
              v-for="category in categories"
              :key="category.id"
              @mouseenter="showCategoryChildren(category.id)"
              :class="{ 'bg-[#FBFBFB]': activeCategory === category.id }"
              class="display-2 flex cursor-pointer px-6 pt-4 hover:bg-[#FBFBFB]"
            >
              {{ category.title }}
            </NuxtLink>
          </div>
          <div
            v-if="
              activeCategoryObject &&
              activeCategoryObject.children &&
              activeCategoryObject.children.length
            "
            class="w-1/2 bg-[#FBFBFB]"
          >
            <div class="px-8 pt-4 text-xl font-bold">
              {{ activeCategoryObject.title }}
            </div>
            <div
              v-for="subcategory in activeCategoryObject.children"
              :key="subcategory.id"
            >
              <NuxtLink
                @click.prevent="handleSubCategoryClick(subcategory.id)"
                :to="{
                  path: $features?.home ? paths.products.index : paths.home,
                  query: {
                    sub_categories: subcategory.id,
                  },
                }"
                class="display-2 block cursor-pointer px-8 pt-4 hover:text-[#c03054]"
              >
                {{ subcategory.title }}
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script setup lang="ts">
import paths from "~/constants/paths";
import { useFiltersStore } from "~/store/Features/ProductManagement/filtersStore";
import { Category } from "~/types/ProductManagement/Categories";

const props = defineProps<{
  type: string;
  categories: Category[];
}>();
const emits = defineEmits(["toggle"]);

const filterStore = useFiltersStore();
const showDropdown = ref(false);
const activeCategory = ref("");
const route = useRoute();

const showCategoryChildren = (subcategoryId: string) => {
  activeCategory.value = subcategoryId;
};

const activeCategoryObject = computed(() => {
  return props.categories.find(
    (category: any) => category.id === activeCategory.value,
  );
});

const handleCategoryClick = (categoryId: string) => {
  showDropdown.value = false;
  const currentPath = route.path;
  if (currentPath === "/products" || !useNuxtApp().$features?.home) {
    filterStore.clearAllFilters();
    filterStore.applyFilter("categories", categoryId);
  }
};

const handleSubCategoryClick = (subcategoryId: string) => {
  showDropdown.value = false;
  const currentPath = route.path;
  if (currentPath === "/products" || !useNuxtApp().$features?.home) {
    filterStore.clearAllFilters();
    filterStore.applyFilter("sub_categories", subcategoryId);
  }
};

onMounted(() => {
  const firstCategory = props.categories[0];
  activeCategory.value = firstCategory.id;
  showCategoryChildren(firstCategory.id);
});
</script>
