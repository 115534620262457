<template>
  <div>
    <div @click="toggleAbout" class="footer-section md:hover:cursor-default">
      <div class="footer-header md:mb-3">
        {{ $t("footer.about_section.titles.about") }}
      </div>
      <IconsArrowUpIcon
        :class="{ 'rotate-[180deg]': !showAbout }"
        class="block transform md:hidden"
      />
    </div>
    <div :class="{ '!mt-0 h-0 md:h-full': !showAbout }" class="items-wrapper">
      <NuxtLink
        v-if="$features?.contact_us"
        :to="{ path: '/contactUs' }"
        class="footer-item"
      >
        {{ $t("footer.about_section.titles.contact_us") }}</NuxtLink
      >
      <NuxtLink :to="{ path: '/faq' }" class="footer-item">{{
        $t("informative.faq_page.faqs")
      }}</NuxtLink>
      <NuxtLink
        v-for="(page, index) in aboutSectionPages"
        :key="page.key"
        :to="getPathName(page.url)"
        class="footer-item"
        >{{ page.title }}</NuxtLink
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { Page } from "~/types/Footer/HelpPages";

defineProps({
  aboutSectionPages: {
    type: Array as () => Page[],
    default: () => [],
  },
});
const showAbout = ref(false);

const getPathName = (url: string): string => {
  return new URL(url).pathname;
};

const toggleAbout = () => {
  showAbout.value = !showAbout.value;
};
</script>
