<template>
  <!-- List View -->
  <div
    v-if="$features?.categories?.screens?.categories_screen === '1'"
    @click="$emit('viewBrands', true)"
    class="flex cursor-pointer items-center justify-between border-t border-[#C1C1C1] py-5 font-bold"
  >
    <div class="h2 block cursor-pointer">
      {{ $t("informative.home.titles.brands") }}
    </div>
    <IconsArrowUpIcon class="rotate-[-270deg] transform" />
  </div>

  <!-- Small Cards View -->
  <div
    v-else-if="$features?.categories?.screens?.categories_screen === '3'"
    class="flex cursor-pointer items-center gap-8 border-t border-grey-lightest py-5 font-bold"
    @click="$emit('viewBrands', true)"
  >
    <div
      class="image flex h-[80px] w-[80px] items-center justify-center bg-[#F5F5F5]"
    >
      <span class="h2 capitalize text-grey-lightest">
        {{ $t("informative.home.titles.brands") }}
      </span>
    </div>
    <div class="h2 subcategory-title block cursor-pointer uppercase">
      {{ $t("informative.home.titles.brands") }}
    </div>
  </div>

  <!-- Grid View -->
  <div
    v-else
    @click="$emit('viewBrands', true)"
    class="flex cursor-pointer flex-col items-center justify-center border-[#C1C1C1] px-[1.7rem] font-bold"
  >
    <div
      class="flex h-[120px] w-[156px] items-center justify-center bg-[#F5F5F5]"
    >
      <span class="h3 capitalize text-grey-lightest">
        {{ $t("informative.home.titles.brands") }}
      </span>
    </div>
    <div class="display-2 mt-4 block cursor-pointer capitalize">
      {{ $t("informative.home.titles.brands") }}
    </div>
  </div>
</template>
