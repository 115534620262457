<template>
  <div>
    <div
      @click="toggleLinks"
      :class="{ 'md:my-0': !showLinks }"
      class="footer-section md:hover:cursor-default"
    >
      <div class="footer-header md:mb-3">
        {{ $t("footer.about_section.titles.quick_links") }}
      </div>
      <IconsArrowUpIcon
        :class="{ 'rotate-[180deg]': !showLinks }"
        class="block transform md:hidden"
      />
    </div>
    <div
      :class="{ '!my-0 h-0 md:my-0 md:h-full': !showLinks }"
      class="items-wrapper"
    >
      <NuxtLink :to="{ name: 'index' }" class="footer-item">
        {{ $t("footer.quick_links.home") }}
      </NuxtLink>

      <NuxtLink :to="{ name: 'cart' }" class="footer-item">
        {{ $t("footer.quick_links.cart") }}
      </NuxtLink>

      <NuxtLink :to="{ name: 'account' }" class="footer-item">
        {{ $t("footer.quick_links.account") }}
      </NuxtLink>

      <NuxtLink
        v-if="$features?.wishlist"
        :to="{ name: 'wishlist' }"
        class="footer-item"
      >
        {{ $t("footer.quick_links.wishlist") }}
      </NuxtLink>
    </div>
  </div>
</template>
<script setup lang="ts">
const showLinks = ref(false);

const toggleLinks = () => {
  showLinks.value = !showLinks.value;
};
</script>
