<template>
  <div v-if="!user" class="bg-[#F0F0F0] py-4">
    <div class="btn-text flex items-center justify-center text-center text-lg">
      <NuxtLink
        class="mr-1 block cursor-pointer font-bold underline"
        :to="{ name: 'login' }"
      >
        {{ $t("common.titles.sign_in") }}
      </NuxtLink>
      <span>/</span>
      <NuxtLink
        class="ml-1 block cursor-pointer font-bold underline"
        :to="{ name: 'register' }"
      >
        {{ $t("common.titles.register") }}
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
const user = useCookie("hs-token").value;
</script>
