<template>
  <div
    v-if="$features?.cart?.configs?.enableOnlinePayment"
    class="flex flex-col items-center gap-5 md:flex-row md:gap-4"
  >
    <div class="display-2">
      {{ $t("footer.copyrights.payment") }}
      {{ $t("footer.copyrights.powered_by") }}
    </div>
    <div class="flex gap-3">
      <img
        src="@/assets/icons/paymob_logo.svg"
        alt="payment-logo"
        class="h-10 rounded-sm bg-white p-2"
      />

      <div class="flex h-10 items-center gap-1 rounded-sm bg-white p-2">
        <img src="@/assets/icons/mastercard_logo.svg" alt="payment-logo" />
        <img src="@/assets/icons/visa_logo.svg" alt="payment-logo" />
        <img src="@/assets/icons/meza_logo.svg" alt="payment-logo" />
      </div>
    </div>
  </div>
</template>
