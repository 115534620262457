<template>
  <div
    class="flex w-full flex-col-reverse justify-start md:flex-col md:justify-start"
  >
    <div class="social-wrapper">
      <div v-for="(link, index) in socialLinks" :key="index">
        <NuxtLink :to="link.content" target="_blank" rel="noopener noreferrer">
          <img :src="link.image" :alt="link.title" class="mr-4 h-8 w-8" />
        </NuxtLink>
      </div>
    </div>

    <div v-if="tax.length > 0" class="tax">
      {{ $t("footer.social_section.tax") }} {{ tax[0]?.content }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { BaseResponse } from "~/types/Common/APIs";
import { FooterInfo, SocialLink } from "~/types/Footer/FooterInfo";

const footerInfo = ref<FooterInfo[]>([]);
const tax = ref<FooterInfo[]>([]);

const getFooterInfo = async () => {
  const { res } =
    await useApi<BaseResponse<FooterInfo[]>>(`/api/v1/contact-info`);

  if (res?.data) {
    footerInfo.value = res.data;
  }

  const taxInfo = res?.data.find(
    (item: { type: string }) => item.type === "tax registration ID",
  );
  if (taxInfo) {
    tax.value = [taxInfo];
  } else {
    tax.value = [];
  }
};

const socialLinks = computed(() => {
  return footerInfo.value
    .filter((item) => item.type === "social media")
    .map((item) => ({
      title: item.title,
      content: item.content,
      type: item.type,
      image: item.image,
    })) as SocialLink[];
});

onMounted(() => {
  getFooterInfo();
});
</script>
