<template>
  <div>
    <!--Desktop-->
    <div id="brands-container" class="hidden md:block">
      <BrandsDesktopAlphabeticalList
        v-if="brandsLayout === '1'"
        class="absolute left-0"
        :brands="brands"
      />
      <BrandsDesktopGridView v-if="brandsLayout === '2'" :brands="brands" />
      <BrandsDesktopSmallCards v-if="brandsLayout === '3'" :brands="brands" />
    </div>
    <!--Mobile-->
    <div class="md:hidden">
      <BrandsMobileAlphabeticalList
        v-if="brandsLayout === '1'"
        :brands="brands"
      />
      <BrandsMobileGridView v-if="brandsLayout === '2'" :brands="brands" />
      <BrandsMobileSmallCards v-if="brandsLayout === '3'" :brands="brands" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Brand } from "~/types/ProductManagement/Brand";
import BrandsDesktopAlphabeticalList from "../Layouts/Brands/Desktop/AlphabeticalList.vue";
import BrandsDesktopGridView from "../Layouts/Brands/Desktop/GridView.vue";
import BrandsDesktopSmallCards from "../Layouts/Brands/Desktop/SmallCards.vue";
import BrandsMobileAlphabeticalList from "../Layouts/Brands/Mobile/AlphabeticalList.vue";
import BrandsMobileGridView from "../Layouts/Brands/Mobile/GridView.vue";
import BrandsMobileSmallCards from "../Layouts/Brands/Mobile/SmallCards.vue";

defineProps({ brands: Object as PropType<Brand[]> });

const { $features } = useNuxtApp();
const brandsLayout = $features?.brands?.screens?.brands_screen as string;
</script>
