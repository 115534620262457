<template>
  <div class="md:hidden">
    <!--List view categories mobile-->
    <template v-if="categoriesLayout === '1'">
      <div>
        <div class="px-[1.7rem]">
          <div v-for="(category, i) in categories" :key="category.id">
            <div
              :class="{ 'border-none': i == 0 }"
              class="flex cursor-pointer items-center justify-between border-t border-[#C1C1C1] py-5 font-bold"
              @click="handleCategoryClick(category)"
            >
              <div class="h2 block cursor-pointer">{{ category.title }}</div>
              <IconsArrowUpIcon class="rotate-[-270deg] transform" />
            </div>
          </div>

          <div v-if="!$features?.categories">
            <div
              @click="
                $router.push(
                  $features?.home ? paths.products.index : paths.home,
                ),
                  $parent?.$emit('close')
              "
              class="flex cursor-pointer items-center justify-between border-t border-[#C1C1C1] py-5 font-bold"
            >
              <div class="h2 block cursor-pointer">
                {{ $t("informative.home.titles.shop_collection") }}
              </div>
              <IconsArrowUpIcon class="rotate-[-270deg] transform" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <!--Grid view categories mobile-->
    <template v-if="categoriesLayout === '2'">
      <div class="l3-mobile-cat-grid mt-4">
        <div class="flex items-center justify-center">
          <div class="gap-l-[18px] gap-x-50 grid grid-cols-2 gap-y-3 px-0">
            <div v-for="(category, i) in categories" :key="category.id">
              <div
                @click="handleCategoryClick(category)"
                :class="{ 'border-none': i == 0 }"
                v-if="category.title && category.title != ''"
                class="flex cursor-pointer flex-col items-center justify-center border-[#C1C1C1] px-[1.7rem] font-bold"
              >
                <CommonUImg
                  class="h-[120px] w-[156px]"
                  :src="category.category_image"
                  :alt="category.title"
                />
                <span class="display-2 mt-4 block cursor-pointer">
                  {{ category.title }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!--small cards view categories mobile-->
    <template v-if="categoriesLayout === '3'">
      <div class="l3-mobile-cat-grid">
        <div>
          <div v-for="(category, i) in categories" :key="category.id">
            <div
              @click="handleCategoryClick(category)"
              :class="[
                { 'border-none': i == 0 },
                { 'border-t-[0.5px] border-[#C1C1C1] ': i != 0 },
              ]"
              v-if="category.title"
              class="flex cursor-pointer items-center px-[1.7rem] py-5"
            >
              <CommonUImg
                class="h-[80px] w-[80px] rounded-lg"
                :src="category.category_image || ''"
                :alt="category.title"
              />

              <span class="subcategory-title h2 ml-5 block cursor-pointer">
                {{ category.title }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import paths from "~/constants/paths";
import { Category } from "~/types/ProductManagement/Categories";
defineProps({
  categories: Array as PropType<Category[]>,
  categoriesLayout: String,
});

const emit = defineEmits(["category-selected", "close"]);

const router = useRouter();

const handleCategoryClick = (category: Category) => {
  emit("category-selected", category);
  if (!category.children || category.children.length === 0) {
    router.push({
      path: useNuxtApp().$features?.home ? paths.products.index : paths.home,
      query: { categories: category.id },
    });
    emit("close");
  }
};
</script>
