<template>
  <div
    v-if="$features?.categories?.configs?.haveSubCategories"
    id="subcategory-menu"
    class="hidden md:block"
  >
    <!--Desktop Category layouts-->
    <CategoriesDesktopListView
      v-if="categoriesLayout === '1'"
      :category="category"
    />
    <CategoriesDesktopGridView
      v-if="categoriesLayout === '2'"
      :category="category"
    />
    <CategoriesDesktopSmallCards
      v-if="categoriesLayout === '3'"
      :category="category"
    />
  </div>
</template>

<script setup lang="ts">
import CategoriesDesktopSmallCards from "~/components/features/Header/Layouts/Categories/Desktop/SmallCards.vue";
import CategoriesDesktopGridView from "~/components/features/Header/Layouts/Categories/Desktop/GridView.vue";
import CategoriesDesktopListView from "~/components/features/Header/Layouts/Categories/Desktop/ListView.vue";

defineProps(["category", "categoriesLayout"]);

const { $features } = useNuxtApp();
const categoriesLayout = $features?.categories?.screens
  ?.categories_screen as string;
</script>
