<template>
  <nav v-if="$features?.categories || $features?.brands || $features?.bundles">
    <menu
      id="categories-container"
      class="relative hidden h-[2.8rem] w-full justify-center bg-[#FBFBFB] md:border-x-[#F0F0F0] lg:flex"
    >
      <template v-if="!isMenuLoading">
        <template v-if="$features?.categories">
          <AllCategories
            v-if="categories && categories.length > maxVisibleCategories"
            :categories="categories"
            :type="'category'"
            @toggle="toggle"
          />
          <ItemHeader
            v-for="category in visibleCategories"
            class="categories-item-header"
            :key="category.id"
            :item="category"
            :type="'category'"
            :show="category.id == selectedCategory"
            @toggle="toggle"
          />
        </template>
        <template v-if="$features?.brands">
          <ItemHeader
            class="brands-item-header"
            :show="showBrands"
            :type="'brands'"
            :item="brands"
            @toggle="viewBrands"
          />
        </template>
        <template v-if="$features?.bundles">
          <ItemHeader
            class="brands-item-header"
            :show="showBundles"
            :type="'bundles'"
            :item="bundles"
            @toggle="viewBundles"
          />
        </template>
      </template>
      <CommonSkeletonLoader
        v-else
        class="h-full w-full animate-pulse bg-gray-200"
      />
    </menu>

    <div @click="setShowMenu(true)" class="md:hidden">
      <IconsMenuIcon />
    </div>

    <MobileModal
      v-if="showMenu"
      :categories="categories"
      :bundles="bundles"
      @close="setShowMenu(false)"
    />
  </nav>
</template>

<script setup lang="ts">
import ItemHeader from "~/components/features/Header/MenuNav/ItemHeader.vue";
import MobileModal from "~/components/features/Header/MenuNav/MobileModal.vue";
import useHeaderMenuInfo from "~/composables/features/Header/useHeaderMenuInfo";
import useBundles from "~/composables/ProductMangment/useBundles";
import AllCategories from "~/components/features/Header/MenuNav/AllCategories.vue";

const { categories, brands, getCategories, getBrands } = useHeaderMenuInfo();
const { bundles, getBundles } = useBundles();

const showBrands = ref(false);
const showBundles = ref(false);
const showMenu = ref(false);
const maxVisibleCategories = 8;
const selectedCategory = ref<string | null>(null);
const categoriesFetched = ref(false);

const { $features } = useNuxtApp();

const isMenuLoading = ref(true);

const visibleCategories = computed(() => {
  return categories.value?.slice(0, maxVisibleCategories);
});

const viewBrands = (val: boolean) => {
  showBrands.value = val;
};

const viewBundles = (val: boolean) => {
  showBundles.value = val;
};

const setShowMenu = (val: boolean) => {
  showMenu.value = val;
  selectedCategory.value = null;
  showBrands.value = false;
  showBundles.value = false;

  if (val) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
};

const toggle = async (id: string) => {
  selectedCategory.value = selectedCategory.value === id ? null : id;
};

onMounted(async () => {
  if (!categoriesFetched.value) {
    await getCategories();
    categoriesFetched.value = true;
  }
  await getBrands();
  await getBundles();
  isMenuLoading.value = false;
});
</script>
